<template>
  <section id="presentation" class="lg:pb-24 relative overflow-hidden">
    <div class="block w-full px-4 lg:px-0 2xl:max-w-7xl xl:max-w-6xl mx-auto pt-24 lg:pt-48 pb-12">
      <div class="mx-auto max-w-2xl text-center">
        <h2 class="text-3xl lg:text-4xl gradient-clip" data-aos="fade">{{$t('pages.home.hero.creative-services.title')}} <span>{{$t('pages.home.hero.creative-services.title-word')}}</span></h2>
        <p class="my-10 leading-8 px-2" data-aos="fade" data-aos-delay="50">{{$t('pages.home.hero.creative-services.description')}}</p>
        <glow-button data-aos="fade" data-aos-delay="100" :to="localePath('/contact')">
          {{$t('menu.contact')}}
        </glow-button>
      </div>
    </div>
    <div class="overflow-hidden absolute w-full h-full pointer-events-none top-0">
      <div class="pink-radial absolute"></div>
    </div>

  <LazySectionsHomeOurProcess />

  </section>
</template>
<script>
import glowButton from "../../blocks/glow-button/glow-button";

export default {
  components: {'glow-button': glowButton},
}
</script>
